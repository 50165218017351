<template>
    <div class="add-polt">
        <van-form class="form-container" @submit="onSubmit" :disabled="isAdmin">
            <van-field
                v-model="workOrderInfo.workTitle"
                name="workTitle"
                label="工作标题"
                required
                placeholder="请输入工作标题"
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="workOrderInfo.orgName"
                name="orgName"
                label="所属园区"
                readonly
                placeholder="请选择园区"
                @click="showSelectPark"
                required
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="workOrderInfo.massifName"
                name="massifName"
                readonly
                label="所属地块"
                placeholder="请选择地块"
                @click="showSelectAddress"
                required
                :rules="[{ required: true}]"
            />
            <van-field
                v-model="workOrderInfo.contactName"
                name="contactName"
                label="发布人"
                placeholder="发布人"
            />
            <van-field
                v-model="workOrderInfo.contactPhone"
                name="contactPhone"
                label="联系电话"
                placeholder="请正确手机号码"
                
            />
            <van-field
                v-model="workOrderInfo.workContent"
                rows="2"
                autosize
                name="workContent"
                label="工作内容"
                type="textarea"
                maxlength="100"
                placeholder="请输入工作内容"
                show-word-limit
            /> 
            <!-- <van-field name="switch" label="是否启用" v-if="!isAdd">
                <template #input>
                    <van-switch v-model="switchChecked" size="20" :disabled="isAdmin" />
                </template>
            </van-field> -->
            <div class="form-bottom">
                <van-button round block type="info" native-type="submit" v-show="!isAdmin">保存</van-button>
            </div>
        </van-form>
        <!-- 园区选择 -->
        <van-action-sheet v-model="show" :actions="actions" @select="select" />
        <!-- 地块选择 -->
        <van-action-sheet v-model="showAddress" :actions="actionAddresss" @select="selectAddress" />
    </div>
</template>

<script>
import {listTreeOrg } from '@/api/plotManagement';
import { addMassif, modifyMassif, uploadImg,listPageMassif} from '@/api/plotManagement';
import { addOrder,modifyOrder} from '@/api/workOrder';
import {getToken,getOrgId,getUserType } from '@/utils/cookie'
export default {
    data() {
        return {
            isAdd: true,//判断是新增还是编辑
            workOrderInfo: {
                massifId: null,
                contactName: '',
                contactPhone: '',
                workTitle: '',
                workContent: '',
                orgName:'',
                orgId:''
            },
            switchChecked: true,//是否启用
            show: false,//是否显示选择园区
            showAddress: false,//是否选择地理位置
            activeId: 1,
            activeIndex: 1,
            actions: [],
            actionAddresss: [],
            isAdmin: true,//是否是管理员
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        this.listAllPark();
        this.listPageMassif(2198);
    },
    methods: {
        onSubmit(val) {
            if (this.isAdd) {
                addOrder(this.workOrderInfo).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '新增成功'});
                        this.$router.push({path: '/workOrder'})
                    }
                });
            } else {
                this.workOrderInfo.updater = this.switchChecked ? 1 : 0;
                modifyOrder(this.workOrderInfo).then(res => {
                    if (res.code == 200) {
                        this.$notify({type: 'success', message: '编辑成功'});
                        this.$router.push({path: '/workOrder'})
                    }
                });
            }
            
        },
        onCancel() {

        },
        select(val) {
            this.workOrderInfo.orgName = val.name;
            this.workOrderInfo.orgId = val.orgId;
            this.menuPid = val.orgPid;
            this.show = false;
        },
        selectAddress(val) {
            this.workOrderInfo.massifName = val.name;
            this.workOrderInfo.massifId = val.massifId;
            this.showAddress = false;
        },
        /** 查询所有园区 */
        listAllPark() {
            listTreeOrg({
                orgId: getOrgId(),//当前查询页面
            }).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.orgName;
                        }
                    });
                    this.actions = res.data || [];
                }
            });
        },
        listPageMassif(parkId){
            listPageMassif(
                {parkId,pageSize:0}
                ).then( res => {
                if( res.code === 200 ) {
                    res.data.forEach((item, index) => {
                        if (item) {
                            item.name = item.massifName;
                        }
                    });
                    this.actionAddresss = res.data || [];
                }
            });
        },
        /** 显示选择园区 */
        showSelectPark() {
            if (!this.isAdmin) {
                this.show = true;
            }
        },
        showSelectAddress() {
            if (!this.isAdmin) {
                this.showAddress = true;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.massifName) {
            next(vm => {
                console.log(getUserType())
                let type = getUserType() == 1 ? true : false;
                vm.workOrderInfo = to.params;
                vm.fileList.push({ url: to.params.cropUrl, isImage: true });
                console.log(vm.fileList)
                if (type) {
                    document.title = '任务详情';
                } else {
                    let doms = document.getElementsByTagName('input');
                    doms.forEach(item => {
                        item.setAttribute('placeholder','');
                    });
                    document.title = '编辑任务';
                }
                vm.switchChecked =  to.params.updater == 1 ? true : false;
                vm.isAdd = false;
            })
        } else {
            next(vm => {
                vm.isAdd = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .add-polt {
        height: 100%;
        background-color: #fff;
        .form-container {
            padding: 8px;
            .form-bottom {
                position: fixed;
                left: 12px;
                // bottom: 10px;
                bottom: 74px;
                width: 346px;
            }
        }
    }
</style>
<style lang="scss">
    .add-polt {
        .van-popup {
            width: 100%;
            height: 100%;
            .map-container {
                width: 100%;
                height: 600px;
                margin: 0 auto;
                border-radius: 8px;
                .map {
                    width: 100%;
                    height: 100%;
                    // margin-top: 30px;
                    border-radius: 8px;
                }
            }
            .van-popup__close-icon {
                top: 5px;
                right: 5px;
                color: #a7a9af;
            }
            .map-search {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 346px;
            }
            .van-search {
                background-color: transparent;
            }
        }
        .btn-save {
            position: fixed;
            bottom: 10px;
            left: 12px;
            width: 346px;
        }
    }
    .tangram-suggestion-main {
        left: 25px !important;
        width: 310px !important;
        z-index: 999999;
    }
</style>